import { Route, Routes, useLocation , Link} from "react-router-dom";
import {Home} from "../home/Home"
import { Layout } from "../components/Layout";
import { Services } from "../services/Services";
import { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useState, useEffect, useCallback } from "react"
import { Item } from "../config/Item";
import { PaymentDelivery } from "../components/PaymentDelivery";
import { Card } from "../products/Card";
import { VideoPlayer } from "../components/VideoPlayer";
import {BagPage} from "../header/pages/BagPage"
import { Products } from "../products/Products";
import { Contact } from "../components/Contact";
import { Favorit } from "../header/pages/Favorit";
import { AboutAs } from "../components/AboutUs";
import { GiftCard } from "../gift/GiftCard";
import { BuyGift } from "../gift/pages/BuyGift";
import { PrivacyPolicy } from "../components/PrivacyPolicy";
import {Tabs} from "../modules/Tabs"
import {Success} from '../modules/Success'
import {Fail} from '../modules/Fail'
import { Search } from "../header/pages/Search";
import { Result } from "../modules/Result";
import {Ameriasuccess} from '../config/Ameriares'
import {NotFound} from "../error/NotFound"
import i18next from "i18next";
import ReactGA from 'react-ga';
const Track_ID= 'G-NYXCWZ713D';



ReactGA.initialize(Track_ID);



const  Routing = () => {
    const {t, i18n} = useTranslation()
    const location = useLocation();

    if (window.location.pathname === "/"){
        window.location.replace(`/${i18n.language}`)
    }


    // const stateObj = { foo: "productname" };
    // window.history.replaceState(stateObj, "", `${i18n.language}`);
    // const stateOb = { foo: "product" };
    // window.history.replaceState(stateOb, "", `${i18n.language}`);

    function getAllData(){
        if ( window.location.pathname === `/am` || window.location.pathname === `/ru`
            || window.location.pathname === `/en`) {

          window.history.pushState('',undefined, `/${i18n.language}`)
        }
        else if( window.location.pathname === `/Services/am` || window.location.pathname === `/Services/en` || window.location.pathname === `/Services/ru`){
            window.history.pushState('',undefined,`/Services/${i18n.language}`)
        }
        else if( window.location.pathname === `/videoLesson/am` || window.location.pathname === `/videoLesson/en` || window.location.pathname === `/videoLesson/ru`){
            window.history.pushState('',undefined,`/videoLesson/${i18n.language}`)
        }
        else if( window.location.pathname === `/PaymentDelivery/am` || window.location.pathname === `/PaymentDelivery/en` || window.location.pathname === `/PaymentDelivery/ru`){
            window.history.pushState('',undefined,`/PaymentDelivery/${i18n.language}`)
        }
        else if( window.location.pathname === `/giftCard/am` || window.location.pathname === `/giftCard/en` || window.location.pathname === `/giftCard/ru`){
            window.history.pushState('',undefined,`/giftCard/${i18n.language}`)
        }
        else if( window.location.pathname.includes(`/catalog`) ){
            let x=  window.location.pathname.split('/').slice(0,-1).join('/') + `/${i18n.language}`
            window.history.pushState('',undefined,  `${x}`)
        }
        else if( window.location.pathname.includes(`/menucatalog`) ){
            let y=  window.location.pathname.split('/').slice(0,-1).join('/') + `/${i18n.language}` + window.location.search
            window.history.pushState('',undefined,  `${y}`)

        }

        else if( window.location.pathname === `/about/am` || window.location.pathname === `/about/en` || window.location.pathname === `/about/ru`){
            window.history.pushState('',undefined,`/about/${i18n.language}`)
        }
        else if( window.location.pathname === `/contact/am` || window.location.pathname === `/contact/en` || window.location.pathname === `/contact/ru`){
            window.history.pushState('',undefined,`/contact/${i18n.language}`)
        }
    }

    const handleLanguageChanged = useCallback(() => {
        getAllData();
    }, []);
    useEffect(() => {
        i18next.on('languageChanged',handleLanguageChanged )

        return () => {
            i18n.off('languageChanged', handleLanguageChanged);
        };

    }, [handleLanguageChanged]);

    return (
        <>
                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route index   path= {`/:${i18n.language}`} element={<Home />}/>
                        <Route path="card" element={<Card />}/>
                        <Route path={`Services/:${i18n.language}`} element={<Services/>} />
                        <Route path={`PaymentDelivery/:${i18n.language}`} element={<PaymentDelivery/>} />
                        <Route path="bagPage" element={<BagPage />} />
                        <Route path={`catalog/:category1/:category2/:category3/:productCode/:${i18n.language}`} element={<Item/>}/>
                        <Route path={`catalog/:category1/:productCode/:${i18n.language}`} element={<Item/>}/>
                        <Route path={`videoLesson/:${i18n.language}`} element={<VideoPlayer/>}/>
                        <Route path={`contact/:${i18n.language}`} element={<Contact/>} />
                        <Route path="favorit" element={<Favorit/>}/>
                        <Route path={`about/:${i18n.language}`} element={<AboutAs/>} />
                        <Route path={`giftCard/:${i18n.language}`} element={<GiftCard/>}/>
                        <Route path={`giftCard/buyGift`} element={<BuyGift/>}/>
                        <Route path="privacyPolicy" element={<PrivacyPolicy/>}/>
                        <Route path="search" element={<Search />} />
                        <Route path="abio/public/cart/idram/success" element={<Success/>}/>
                        <Route path="abio/public/cart/ameria/result" element={<Ameriasuccess/>}/>
                        <Route path="abio/public/cart/tellcell/result" element={<Result/>}/>
                        <Route path="abio/public/cart/idram/fail" element={<Fail/>}/>
                        <Route path="tabs" element={<Tabs/>}/>
                        <Route
                            path={`menucatalog/:category1?/:category2?/:category3?/:catalogs/:${i18n.language}`}
                            element={<Products key={location.key} />}
                        />
                        <Route
                            path={`menucatalog/:category1?/:category2?/:catalogs/:${i18n.language}`}
                            element={<Products key={location.key} />}
                        />
                    </Route>
                    <Route path="*" element={<NotFound/>}/>
                </Routes>
        </>
    );
}

export default Routing;
