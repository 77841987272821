import React from 'react'
import {useState, useEffect} from "react";

import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import '../../scss/tabs.scss'

import {useLocation} from 'react-router-dom';
import axios from "axios";
import {Cookies} from "react-cookie";


const Result = () => {

    const navigate = useNavigate()

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const edpBillNo = queryParams.get('order');
    let url = process.env.REACT_APP_BASE_URL;

    const instance = axios.create({
        withCredentials: true
    })
    const [order, setOrder] = useState('')
    const [email, setEmail] = useState('')
    const [payed, setPayed] = useState()
    const {t, i18n} = useTranslation()

    const cookies = new Cookies();
    const getOrderInfo = async () => {
        await instance.get(`${url}/abio/public/cart/resetOrder`, {
            headers: {
                'XSRF_TOKEN_HEADER': cookies.get('XSRF_TOKEN_S'),
                'X_ORDER_XSRF_TOKEN': cookies.get('ORDER_XSRF_TOKEN'),
            },
            params: {
                orderId: edpBillNo
            }
        }).then(response => {
            setOrder(response.data.id)
            setEmail(response.data.email)
            setPayed(response.data.payed)
        }).then(response => setOrder(response.data.id)).catch(error => console.log(error))
    }
    useEffect(() => {
        getOrderInfo()

    }, []);
    console.log(payed)

    return (
        <div className='succes'>

            {payed ? (
                <>
                    <div className='modalCont'>
                        <h2><input type="checkbox" className="modalcheck" checked/>{t("Tabs.thanks")}</h2>

                    </div>
                    <div className="modalText">{t("Tabs.thanksdetail1")} <span> {order} </span>
                        {t("Tabs.thanksdetail2")} {email} {t("Tabs.thanksdetail3")} </div>
                    <Link to={`/${i18n.language}`}>
                        <button className="continuShop">{t("shop.continue")}</button>
                    </Link>
                </>
            ) : (<>
                    <div className='amerfail'>
                        <div className='modalCont'>
                            <h2 className='errortext'>{t("Tabs.thankserror")}</h2>

                        </div>

                        <Link to={`/${i18n.language}`}>
                            <button className="continuShop">{t("shop.continue")}</button>
                        </Link>
                    </div>
                </>

            )

            }


        </div>
    )
}

export {Result}
